/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '~layouts/layout';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import PageSection from '~2-components/PageSection/PageSection';
import PracticeAreasList from '~2-components/PracticeAreasList/PracticeAreasList';
import formatPhoneNumber from '~utils/formatPhoneNumber';
import RichText from '~3-data-components/RichText';
import NewsListKontent from '~3-data-components/NewsListKontent';
import useSiteMetadata from '~3-data-components/useSiteMetaData';
import Helmet from 'react-helmet';
import redirectTo from '~/utils/redirect';

export const query = graphql`
    query($id: String!) {
        kontentItemProfile(id: { eq: $id }) {
            system {
                id
                type
                name
            }
            elements {
                first_name {
                    value
                }
                last_name {
                    value
                }
                page_url {
                    value
                }
                position {
                    value
                }
                phone {
                    value
                }
                email {
                    value
                }
                page_metadata__share_description {
                    value
                }
                page_metadata__share_image {
                    value {
                        url
                    }
                }
                page_metadata__share_title {
                    value
                }
                page_metadata__twitter_creator {
                    value
                }
                client_side_redirection__redirect_url {
                    value
                }
                location {
                    value {
                        ... on kontent_item_location {
                            id
                            elements {
                                location_city {
                                    value
                                }
                                location_address {
                                    value
                                }
                                state {
                                    value
                                }
                                postcode {
                                    value
                                }
                            }
                        }
                    }
                }
                law_institute_specialist_accreditation {
                    value {
                        codename
                    }
                }
                hero_image {
                    value {
                        url
                        description
                        name
                        fluid(maxWidth: 780, maxHeight: 680) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                }
                mobile_image {
                    value {
                        url
                        description
                        name
                        fluid(maxWidth: 1440, maxHeight: 800) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                }
                about {
                    modular_content {
                        system {
                            id
                            type
                            codename
                        }
                    }
                    images {
                        description
                        image_id
                        width
                        height
                        fluid(maxWidth: 996) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                    links {
                        codename
                        link_id
                        type
                        url_slug
                    }
                    value
                }
                practice_areas {
                    value {
                        ... on kontent_item_general_content {
                            id
                            elements {
                                title {
                                    value
                                }
                                page_slug {
                                    value
                                }
                            }
                        }
                    }
                }
                rich_text {
                    modular_content {
                        system {
                            id
                            type
                            codename
                        }
                    }
                    images {
                        description
                        image_id
                        width
                        height
                        fluid(maxWidth: 996) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                    links {
                        codename
                        link_id
                        type
                        url_slug
                    }
                    value
                }
                show_recent_articles {
                    value {
                        codename
                    }
                }
            }
        }
        allKontentItemSiteSettings {
            nodes {
                elements {
                    homepage {
                        value {
                            ... on kontent_item_general_content {
                                elements {
                                    page_slug {
                                        value
                                    }
                                }
                            }
                        }
                    }
                    team_listing_page {
                        value {
                            ... on kontent_item_general_content {
                                elements {
                                    page_slug {
                                        value
                                    }
                                    title {
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ProfileTemplate = ({ data, location }) => {
    const redirectUrl = data.kontentItemProfile.elements.client_side_redirection__redirect_url.value;
    redirectTo(redirectUrl);

    const pageInfo = data.kontentItemProfile.system;
    const profile = data.kontentItemProfile.elements;

    const pageSlug = profile.page_url.value;

    const firstName = profile.first_name.value;
    const lastName = profile.last_name.value;
    const fullName = `${firstName} ${lastName}`;

    const siteSettings = data.allKontentItemSiteSettings.nodes[0].elements;
    const homepageSlug = siteSettings.homepage.value.length > 0 ? siteSettings.homepage.value[0].elements.page_slug.value : '';
    const teamListingData = siteSettings.homepage.value.length > 0 ? siteSettings.team_listing_page.value[0].elements : {};
    const breadcrumbs = [{
        title: 'Home',
        pageSlug: homepageSlug,
    }, {
        title: teamListingData.title ? teamListingData.title.value : '',
        pageSlug: teamListingData.page_slug ? teamListingData.page_slug.value : '',
    }, {
        title: fullName,
        pageSlug: pageSlug,
        isActive: true,
    }];

    const aboutText = profile.about;
    const practiceAreas = profile.practice_areas.value;
    const richText = profile.rich_text;
    const showArticles = profile.show_recent_articles.value[0] && profile.show_recent_articles.value[0].codename === 'show_recent_articles' ? true : false;

    const formattedNumber = formatPhoneNumber(profile.phone.value);

    const hasImage = profile.hero_image.value.length > 0 ? true : false;
    const heroImage = hasImage ? profile.hero_image.value[0] : null;
    const mobileImage = profile.mobile_image.value.length > 0 ? profile.mobile_image.value[0] : null;

    const { site_title, site_description, site_url, default_share_image, twitter_site } = useSiteMetadata();
    const customMetaDescription = profile.page_metadata__share_description.value;
    const customMetaImage = profile.page_metadata__share_image.value;
    const customMetaTitle = profile.page_metadata__share_title.value;
    const metaDataTwitterCreator = profile.page_metadata__twitter_creator.value.length ? profile.page_metadata__twitter_creator.value : twitter_site.value;
    const pagePath = `${site_url.value}${location.pathname}`;
    const defaultShareImage = default_share_image.length > 0 ? default_share_image.value[0].url : '';
    const metaDataImage = customMetaImage.length ? customMetaImage[0].url : defaultShareImage;
    const metaDataTitle = customMetaTitle.length ? customMetaTitle : fullName;
    const metaDataDescription = customMetaDescription.length ? customMetaDescription : site_description.value;

    const formattedLocations = profile.location.value.map(location => {
        return {
            name: location.elements.location_city.value,
            address: `${location.elements.location_address.value}, ${location.elements.location_city.value}, ${location.elements.state.value} ${location.elements.postcode.value}`,
        };
    });

    const [setProfileBanner] = useState({
        breadcrumbs: breadcrumbs,
        fullName: fullName,
        position: profile.position.value,
        location: formattedLocations,
        phone: {
            txt: profile.phone.value,
            url: `tel:${formattedNumber}`
        },
        email: {
            txt: `Email`,
            url: `mailto:${profile.email.value}`
        },
        hasImage: hasImage,
        heroImage: heroImage,
        mobileImage: mobileImage,
    });

    return (
        <Layout>
            <RenderedContext.Provider value={setProfileBanner}>
                <Header />
            </RenderedContext.Provider>

            <Helmet
                title={`${metaDataTitle} | ${site_title.value}`}
            >
                <meta name="description" content={metaDataDescription} />
                <meta property="og:type" content='website' />
                <meta property="og:site_name" content={site_title.value} />
                <meta property="og:url" content={pagePath} />
                <meta property="og:title" content={metaDataTitle} />
                <meta property="og:description" content={metaDataDescription} />
                <meta property="og:image" content={metaDataImage} />
                <meta name="twitter:card" value='summary' />
                <meta name="twitter:site" content={twitter_site.value} />
                <meta name="twitter:creator" content={metaDataTwitterCreator} />
                <meta name="twitter:url" content={pagePath} />
                <meta name="twitter:title" content={metaDataTitle} />
                <meta name="twitter:description" content={metaDataDescription} />
                <meta name="twitter:image" content={metaDataImage} />
                <link rel="canonical" href={pagePath} />
            </Helmet>


            <div className="page-content">
                <div className="container">
                    <PageSection
                        textLayout="two_columns"
                        leftColContent={<RichText
                            content={aboutText}
                            images={aboutText.images}
                            links={aboutText.links}
                            linkedItems={aboutText.modular_content}
                        />
                        }
                        rightColContent={<PracticeAreasList practiceAreas={practiceAreas} />}
                    />

                    <RichText
                        content={richText}
                        images={richText.images}
                        links={richText.links}
                        linkedItems={richText.modular_content}
                    />

                    {showArticles &&
                        <NewsListKontent
                            pageInfo={pageInfo}
                        />
                    }
                </div>
            </div>
        </Layout>
    );
};

export default ProfileTemplate;

ProfileTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

